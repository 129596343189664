import React, { useEffect, useState } from "react";
import clsx from "classnames";
import { ReactComponent as Logo } from "modules/common/assets/icons/logo1.svg";
import { ReactComponent as Password } from "modules/admin/assets/icons/password.svg";
import { ReactComponent as Profile } from "modules/admin/assets/icons/profile.svg";
import { Button } from "modules/common/components";
import { useForm } from "react-hook-form";
import { useAuth, useDebouncedLoader } from "modules/common/hooks";
import API, { setAPIToken } from "api";
import { storeToLS, validators } from "modules/common/utils/functions";
import CONSTANTS, { navigateToAdminRoute } from "modules/common/utils/constants";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import AdminTopNav from "../components/navigation/AdminTopNav";
import toast from "react-hot-toast";

const { routes, STORAGE_KEYS } = CONSTANTS;

const Onboarding = () => {
	const { email } = useLoaderData();
	const { token } = useParams();
	const [loading, setLoading] = useState(false);
	const isLoading = useDebouncedLoader(loading);
	const { authInfo, setAuthInfo } = useAuth();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			password: "",
			confirmPassword: "",
		},
	});

	const onboard = async ({ password, confirmPassword }) => {
		if (password !== confirmPassword) {
			toast.error("Passwords do not match");
			return;
		}
		setLoading(true);
		try {
			const data = await API.admin.onboard({ email, token, password });
			storeToLS(STORAGE_KEYS.AUTH_DATA, data);
			setAuthInfo(data);
			setLoading(false);
			setAPIToken(data.token);
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (authInfo) {
			navigateToAdminRoute(navigate, routes.admin.addListing);
		}
	}, [authInfo, navigate]);

	return (
		<div>
			<AdminTopNav />
			<div className={clsx("min-h-screen pt-[74px] px-5 bg-F6F7FB")}>
				<div className="max-w-[1440px] mx-auto">
					<div className="py-[10vh] flex flex-col space-y-3 justify-center items-center">
						<h1 className="text-[20px] lg:text-[40px] font-bold text-_25253C text-center">
							Set your password to proceed
						</h1>
						<div className="bg-white border-E8EBEC border rounded-10 pt-[61px] pb-[53px] px-[46px] w-full max-w-[396px]">
							<div className="flex justify-center">
								<Logo className="w-[111.47px] h-[44.63px]" />
							</div>
							<form onSubmit={handleSubmit(onboard)} className="mt-[81.37px]">
								<div className="flex flex-col space-y-[37.84px]">
									{/* email */}
									<div className={clsx("pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]")}>
										<Profile className="flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]" />
										<input
											className="cursor-not-allowed pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-white"
											type="email"
											disabled={true}
											defaultValue={email}
										/>
									</div>
									<div
										className={clsx("pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]", {
											"!border-b-[red]": errors.password,
										})}
									>
										<Password className="flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]" />
										<input
											className="pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-white"
											placeholder="Password"
											type="password"
											{...register("password", {
												required: true,
												validate: (v) => validators.validateStrongPassword(v),
											})}
										/>
									</div>
									{/* end email */}
									{/* password */}
									<div
										className={clsx("pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]", {
											"!border-b-[red]": errors.confirmPassword,
										})}
									>
										<Password className="flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]" />
										<input
											className="pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-white"
											placeholder="Confirm Password"
											type="password"
											{...register("confirmPassword", {
												required: true,
												validate: (v) => validators.validateStrongPassword(v),
											})}
										/>
									</div>
									{/* end password */}
								</div>
								<div className="mt-[50px] flex items-center justify-center">
									<Button
										text={"Submit"}
										loading={isLoading}
										type="submit"
										classNames={"w-[229px] h-[50px]"}
									/>
								</div>
							</form>
						</div>
						<div className="mt-5 text-sm">
							<b>Password rules:</b>
							<ul className="mx-auto text-sm">
								<li>At least 8 characters long.</li>
								<li>Contains at least one uppercase letter.</li>
								<li>Contains at least one lowercase letter.</li>
								<li>Contains at least one digit (0-9).</li>
								<li>Contains at least one special character (e.g., !@#$%^&*).</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Onboarding;
