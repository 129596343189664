/* eslint-disable react-hooks/exhaustive-deps */
import { NavigationWrapper, Pagination } from "modules/common/components";
import React, { useEffect, useState } from "react";
import { PropertiesFilter, Property, PropertySkeleton } from "../components";
import API from "api";
import { usePropertyFilters } from "../hooks";
import { useDebounce, useDebouncedLoader } from "modules/common/hooks";
import { isEmpty } from "lodash";

const PawnList = () => {
	const { applyingFilters, searchProperties, getProperties, loading, properties, state, dispatch, metaData } =
		usePropertyFilters({
			allPropertiesFetcher: API.getProperties,
			filteredPropertiesFetcher: API.searchProperties,
		});

	const [textQuery, setTextQuery] = useState("");
	const [hasMounted, setHasMounted] = useState(false);
	const debouncedTextQuery = useDebounce(textQuery, 1000);

	useEffect(() => {
		getProperties();
		setHasMounted(true);
	}, [getProperties]);

	const isLoading = useDebouncedLoader(loading);

	useEffect(() => {
		if (!hasMounted) {
			return;
		}
		const { lastAppliedFilters, ...rest } = state;
		if (!isEmpty(debouncedTextQuery.trim())) {
			searchProperties({ ...rest, text: debouncedTextQuery });
		} else {
			searchProperties({ ...rest });
		}
	}, [debouncedTextQuery]);

	return (
		<NavigationWrapper containerClassName="bg-F6F7FB" title={"Properties"}>
			<div className="bg-_blue h-[120px] lg:hidden" />
			<div className="px-5 bg-F6F7FB pt-[41px] lg:pt-[188px] 1436:max-w-[1436px] 1436:mx-auto 1436:px-[60px]">
				<PropertiesFilter
					applyFilters={searchProperties}
					applyingFilters={applyingFilters}
					state={state}
					dispatch={dispatch}
					{...{ textQuery, setTextQuery }}
				/>
				<h2 className="text-_black text-[32px] lg:text-[40px] leading-[44px] font-bold mt-[43px] lg:mt-[89px] mb-[25px]">
					Pawn Shop Properties
				</h2>
				<p className="text-_black text-base leading-6 mb-5 1436:max-w-[1235px]">
					Selling your real estate to a pawn shop can be a surprisingly good idea in certain situations. Pawn
					shops often offer quick cash deals, even for properties in less-than-ideal condition. This can be a
					lifesaver if you need to sell quickly to avoid foreclosure or other financial troubles. While you
					might not get the full market value, you'll have the peace of mind that comes with a fast and
					hassle-free transaction. Just be sure to research reputable pawn shops and negotiate the best deal
					possible.
				</p>
				<p className="text-_black text-base mb-[14px]">
					<span className="font-bold">
						People may choose to sell their house to a pawn shop for several reasons:
					</span>
				</p>
				<ul className="list-disc ml-5 leading-7">
					<li>
						<span className="font-bold">Quick Cash:</span> Pawn shops often offer fast cash deals, which can
						be beneficial for those in urgent financial situations, such as avoiding foreclosure or covering
						immediate expenses.
					</li>
					<li>
						<span className="font-bold">Distressed Properties: </span> Pawn shops are known for purchasing
						properties that may be in disrepair, have title issues, or require significant renovations. This
						makes them a suitable option for sellers who have difficulty finding traditional buyers.
					</li>
					<li>
						<span className="font-bold">Simplified Process:</span> Pawn shop transactions can be less
						complex and time-consuming than traditional real estate sales, involving fewer paperwork and
						legal procedures. While pawn shops may not offer the highest market value, they provide a
						convenient and efficient solution for those seeking a quick sale.
					</li>
				</ul>
				{/* <div className="mb-[43px] flex flex-col space-y-3">
					<p className="text-xl leading-[22px] font-bold text-_black lg:text-[24px]">
						Properties Reviewed, on Sale and Sold.
					</p>
					<p className="text-xl leading-[22px] font-bold text-_black lg:text-[24px]">
						{" "}
						Call For More Information
					</p>
				</div> */}
				<div className="flex flex-col space-y-[18.09px] mt-10 390:space-y-0 390:grid 450:grid-cols-2 md:grid-cols-330 390:gap-x-4 390:gap-y-[25px] pb-[113.22px] 1436:!grid-cols-[644px_644px] 1436:gap-x-7 1436:gap-y-[33px]">
					{isLoading ? (
						<>
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
						</>
					) : (
						<>
							{properties
								.filter((property) => property.manually === true)
								.map((property) => (
									<Property
										key={property._id}
										price={""}
										status={property.status}
										address={property.address}
										bathroomCount={property.bathroomCount}
										bedroomCount={property.bedroomCount}
										area={property.area}
										propertyType={property.type}
										images={property.pictures}
										id={property._id}
									/>
								))}
						</>
					)}
				</div>
				<Pagination
					metadata={metaData}
					onPageChange={(page) => {
						const { lastAppliedFilters, ...rest } = state;
						searchProperties(rest, null, page);
					}}
				/>
			</div>
		</NavigationWrapper>
	);
};

export default PawnList;
