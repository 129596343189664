/* eslint-disable react-hooks/exhaustive-deps */
import { NavigationWrapper, Pagination } from "modules/common/components";
import React, { useEffect } from "react";
import { Tour, PropertySkeleton } from "../components";
import API from "api";
import { useTourFilters } from "../hooks";
import { useDebouncedLoader } from "modules/common/hooks";
// import { isEmpty } from 'lodash'

const ToursList = () => {
	const { searchTours, getTours, loading, tours, state, metaData } = useTourFilters({
		allToursFetcher: API.getTours,
		filteredToursFetcher: API.searchTours,
	});
	// const [textQuery, setTextQuery] = useState("")
	// const [hasMounted, setHasMounted] = useState(false)
	// const debouncedTextQuery = useDebounce(textQuery, 1000)
	useEffect(() => {
		getTours();
		// setHasMounted(true)
	}, [getTours]);

	const isLoading = useDebouncedLoader(loading);

	// useEffect(() => {
	//     if (!hasMounted) {
	//         return
	//     }
	//     const { lastAppliedFilters, ...rest } = state
	//     if (!isEmpty(debouncedTextQuery.trim())) {
	//         searchTours({ ...rest, text: debouncedTextQuery })
	//     }
	//     else {
	//         searchTours({ ...rest })
	//     }
	// }, [debouncedTextQuery])

	return (
		<NavigationWrapper containerClassName="bg-F6F7FB" title={"3D TOURS"}>
			<div className="bg-_blue h-[120px] lg:hidden" />
			<div className="px-5 bg-F6F7FB pt-[41px] lg:pt-[100px] 1436:max-w-[1436px] 1436:mx-auto 1436:px-[60px]">
				<h2 className="text-_25253C text-[32px] lg:text-[40px] leading-[44px] font-bold mt-[43px] lg:mt-[89px] mb-[25px]">
					Explore Our 3D Property Tours
				</h2>

				<div className="mb-[43px] flex flex-col space-y-3">
					<p className="text-_25253C text-base leading-6 mb-5 1436:max-w-[1235px]">
						Step into the world of potential with our collection of off-market properties that are waiting
						to be transformed. These properties offer exciting opportunities for renovation and improvement.
						From charming fixer-uppers to hidden gems with untapped potential, each space has its own story
						waiting to be written. Take a virtual tour and envision the possibilities of turning these
						properties into your dream home or investment project
					</p>
				</div>
				<div className="flex flex-col space-y-[18.09px] 390:space-y-0 390:grid 450:grid-cols-2 md:grid-cols-330 390:gap-x-4 390:gap-y-[25px] pb-[113.22px] 1436:!grid-cols-[644px_644px] 1436:gap-x-7 1436:gap-y-[33px]">
					{isLoading ? (
						<>
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
							<PropertySkeleton />
						</>
					) : (
						<>
							{tours.map((property) => {
								console.log("Property ID:", property._id);
								return (
									<Tour
										key={property._id}
										price={""}
										status={property.status}
										address={property.address}
										bathroomCount={property.bathroomCount}
										bedroomCount={property.bedroomCount}
										area={property.area}
										propertyType={property.type}
										images={property.pictures}
										id={property._id}
										tag={property.tag}
									/>
								);
							})}
						</>
					)}
				</div>
				<Pagination
					metadata={metaData}
					onPageChange={(page) => {
						const { lastAppliedFilters, ...rest } = state;
						searchTours(rest, null, page);
					}}
				/>
			</div>
		</NavigationWrapper>
	);
};

export default ToursList;
