/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import clsx from "classnames";
import { ReactComponent as Search } from "modules/common/assets/icons/search.svg";
import { Message, MessageSkeleton } from "../components";
import { useData, useDebounce, useDebouncedLoader } from "modules/common/hooks";
import API from "api";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import MessageDetails from "../components/messages/MessageDetails";

const FairCashApplications = () => {
	const [showOnlyStarred, setShowOnlyStarred] = useState(false);
	const [showMessageDetails, setShowMessageDetails] = useState(false);
	const [activeMessage, setActiveMessage] = useState(null);
	const [loading, setLoading] = useState(true);
	const [query, setQuery] = useState("");
	const { data, metadata, setValues } = useData();
	const isLoading = useDebouncedLoader(loading && !metadata?.nextPage);
	const debouncedQuery = useDebounce(query);

	const getFairCashApplications = async (query, page = 1) => {
		setLoading(true);
		try {
			const result = await API.admin.getAllFairCashApplications(query, page);
			setValues(result, page === 1);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	const onStar = async (message) => {
		try {
			const action = message.isStarred ? "unstar" : "star";
			await API.admin.starFairCashApplication({ submissionId: message._id, action });
			const newValues = {
				data: data.map((newMsg) => {
					if (newMsg._id !== message._id) {
						return newMsg;
					}
					return { ...newMsg, isStarred: action === "star" };
				}),
				metadata,
			};
			setValues(newValues, true);
		} catch (e) {}
	};

	useEffect(() => {
		if (debouncedQuery?.trim()?.length) {
			getFairCashApplications(debouncedQuery);
		} else {
			getFairCashApplications();
		}
	}, [debouncedQuery]);

	const onEndReached = () => {
		if (metadata?.nextPage && !isLoading) {
			getFairCashApplications(query ? query : null, metadata.nextPage);
		}
	};
	const scrollRef = useBottomScrollListener(onEndReached);

	return (
		<div className="pt-[21px] lg:pl-9">
			<MessageDetails
				isVisible={showMessageDetails}
				message={activeMessage}
				onClose={() => setShowMessageDetails(false)}
			/>
			<div className="flex flex-col space-y-4 lg:items-center lg:justify-between lg:pr-12 lg:flex-row">
				<div className="h-12 lg:h-[62px] rounded-[50px] border border-E8EBEC bg-white relative lg:w-[451px] overflow-hidden">
					<Search className="hidden lg:block absolute left-[22px] top-[12px] h-[38px] w-[38px] pointer-events-none" />
					<input
						className="h-12 lg:h-[62px] pl-4 lg:pl-[70px] pr-2 w-full outline-none border-none text-base text-_25253C placeholder:text-E8EBEC"
						placeholder="Search Message"
						value={query}
						onChange={({ target: { value } }) => setQuery(value)}
					/>
				</div>
				<div
					className="flex items-center space-x-[10px] cursor-pointer"
					onClick={() => setShowOnlyStarred(!showOnlyStarred)}
				>
					<div
						className={clsx("h-6 w-6 rounded-[6px] border-[2px] border-E8EBEC bg-white", {
							"!bg-_25253C": showOnlyStarred,
						})}
					/>
					<span className="text-base text-white select-none">Starred Messages</span>
				</div>
			</div>
			<div
				ref={scrollRef}
				className="mt-7 flex flex-col space-y-[11px] h-[calc(100vh_-_187px)] overflow-y-auto lg:pr-12 pb-3 fancy-scrollbar"
			>
				{isLoading ? (
					<>
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
						<MessageSkeleton />
					</>
				) : (
					<>
						{(showOnlyStarred ? data.filter((message) => message.isStarred === true) : data).map(
							(message) => (
								<Message
									onClick={() => {
										setActiveMessage(message);
										setShowMessageDetails(true);
									}}
									key={message._id}
									message={message}
									title={"New fair cash application"}
									onStar={onStar}
								/>
							)
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default FairCashApplications;
