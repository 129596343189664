import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Location } from "modules/properties/assets/icons/location.svg";

// import { ReactComponent as Star } from 'modules/properties/assets/icons/star.svg'
import { useParams, useNavigate } from "react-router-dom";
import clsx from "classnames";
import { parseAddress } from "modules/common/utils/functions";
import { PropertyImagesCarousel } from "../../properties/components";
import {
	ContactDetails,
	PropertyDetails,
	MortgageDetails,
	PurchaseDetails,
	LeaseDetails,
	ImportantDetails,
} from "../components";
import API from "api";
import CONSTANTS from "modules/common/utils/constants";

const TabContent = ({ isVisible, children }) =>
	isVisible ? (
		<div className={clsx("pointer-events-all transition-all no-scrollbar overflow-y-auto h-full w-full")}>
			{children}
		</div>
	) : null;

const RtoFormDetail = () => {
	// const { width } = useWindowSize()
	const [detail, setDetail] = useState(null);
	const { formId } = useParams();
	const navigate = useNavigate();

	const goToList = useCallback(() => {
		// navigate(CONSTANTS.routes.admin.rtoFormList)
	}, [navigate]);

	const getDetail = React.useCallback(
		async (formId) => {
			try {
				const data = await API.getSingleRtoForms(formId);
				console.log(data);
				setDetail(data);
			} catch (e) {
				goToList();
			}
		},
		[goToList]
	);

	useEffect(() => {
		getDetail(formId);
	}, [formId, getDetail]);

	const TABS = {
		CONTACT_DETAILS: "Contact Details",
		PROPERTY_DETAILS: "Property Details",
		MORTGAGE_DETAILS: "Mortgage Details",
		PURCHASE_DETAILS: "Purchase Questions",
		LEASE_DETAILS: "Lease Purchase Questions",
		IMPORTANT_DETAILS: "Important Details",
	};
	const [activeTab, setActiveTab] = useState(TABS.CONTACT_DETAILS);

	return (
		// <NavigationWrapper containerClassName={"bg-[#f2f2f2"} handleSEO={false}>
		<>
			{detail && (
				<div>
					<div className="bg-[#F6F7FB] h-[100px] lg:hidden" />
					<div className=" md:pt-[59px]  pb-5">
						<div className="mt-[17px] lg:mt-[10px] flex items-center md:px-8 mb-8">
							{/* <Location className='1436:h-[36px] 1436:w-[36px]' /> */}
							<p className="text-[20px] font-bold text-_25253C leading-[24px] 1436:text-[24px] 1436:leading-[34px]">
								{detail?.contactDetails.fullAddressOfProperty}
							</p>
						</div>
						<div className=" 1436:px-[39px]">
							<PropertyImagesCarousel
								showBackButton="false"
								address={parseAddress(detail?.contactDetails?.fullAddressOfProperty)}
								imageUrls={(detail?.propertyDetails?.propertyPictures || []).map((image) => image.url)}
								backLink={/admin/ + CONSTANTS.routes.admin.rtoFormList}
							/>
						</div>

						<div
							className={clsx(
								" 1436:flex-shrink-0 mt-[37px] rounded-[6.61px] border-[0.66px] pt-[28.69px] px-[10.7px] 1436:pt-[59px] 1436:px-[39px] border-E8EBEC mb-[55px] 1436:mb-0"
							)}
							style={{ boxShadow: "1.94483px 1.94483px 1.94483px 0px rgba(201, 199, 199, 0.50)" }}
						>
							<div className="flex flex-wrap whitespace-nowrap border-b-[0.49px] border-b-_25253C">
								{Object.values(TABS).map((tab) => (
									<button
										onClick={() => setActiveTab(tab)}
										className="flex flex-col space-y-[4.07px] 1436:space-y-[9px]"
										key={tab}
									>
										<div className="px-[7.78px] leading-none 1436:px-4">
											<span
												className={clsx(
													"text-_25253C text-[16px] 1436:text-[20px] leading-none",
													{ "font-bold": tab === activeTab }
												)}
											>
												{tab}
											</span>
										</div>
										<div
											className={clsx("h-[3.4px] 1436:h-[7px] w-full", {
												"bg-FEDF18": tab === activeTab,
											})}
										/>
									</button>
								))}
							</div>
							<div className="flex-1">
								<TabContent isVisible={activeTab === TABS.CONTACT_DETAILS}>
									{detail?.contactDetails && <ContactDetails detail={detail} />}
								</TabContent>
								<TabContent isVisible={activeTab === TABS.IMPORTANT_DETAILS}>
									{detail?.importantDetails && <ImportantDetails detail={detail} />}
								</TabContent>
								<TabContent isVisible={activeTab === TABS.PROPERTY_DETAILS}>
									{detail?.propertyDetails && <PropertyDetails detail={detail} />}
								</TabContent>
								<TabContent isVisible={activeTab === TABS.MORTGAGE_DETAILS}>
									{detail?.mortgageDetails && <MortgageDetails detail={detail} />}
								</TabContent>
								<TabContent isVisible={activeTab === TABS.PURCHASE_DETAILS}>
									{detail?.purchaseRelatedDetails && <PurchaseDetails detail={detail} />}
								</TabContent>
								<TabContent isVisible={activeTab === TABS.LEASE_DETAILS}>
									{detail?.leasePurchaseDetails && <LeaseDetails detail={detail} />}
								</TabContent>
							</div>
						</div>
						{/* <ContactDetails detail={detail}/>
                        <PropertyDetails detail={detail}/>
                        <MortgageDetails detail={detail}/>
                        <PurchaseDetails detail={detail}/>
                        <LeaseDetails detail={detail}/> */}
					</div>
				</div>
			)}
		</>
		// </NavigationWrapper>
	);
};

export default RtoFormDetail;
