import React, { useState } from "react";
import clsx from "classnames";
import { ReactComponent as Profile } from "modules/admin/assets/icons/profile.svg";
import { useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import { Button } from "modules/common/components";
import { useDebouncedLoader } from "modules/common/hooks";
import API from "api";

const InviteUser = () => {
	const [loading, setLoading] = useState(false);
	const isLoading = useDebouncedLoader(loading);
	const {
		formState: { errors },
		handleSubmit,
		register,
		reset,
	} = useForm({ defaultValues: { email: "" } });
	const onSubmit = async (data) => {
		setLoading(true);
		try {
			await API.admin.inviteNewAdmin(data);
			reset({ email: "" });
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};
	return (
		<div className="p-0 pt-5 md:pt-[21px] md:pl-9">
			<h1 className="text-xl md:text-[40px] font-bold text-_25253C mb-6">Invite an Administrator</h1>
			<form className="flex flex-col p-5 space-y-4 bg-white" onSubmit={handleSubmit(onSubmit)}>
				<div
					className={clsx("pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]", {
						"!border-b-[red]": errors.email,
					})}
				>
					<Profile className="flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]" />
					<input
						className="pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-white"
						placeholder="abc@gmail.com"
						type="email"
						{...register("email", { required: true, validate: (v) => validators.validateEmail(v) })}
					/>
				</div>
				<div className="mt-[50px]">
					<Button text={"Send invite"} loading={isLoading} type="submit" classNames={"w-[229px] h-[50px]"} />
				</div>
			</form>
		</div>
	);
};

export default InviteUser;
