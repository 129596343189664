import { NavigationWrapper } from "modules/common/components";
import React, { useEffect, useState } from "react";
import { ReactComponent as DiamondsLg } from "modules/home/assets/images/diamonds-lg.svg";
import { useParams } from "react-router-dom";
import StepForm from "../components/StepForm";
import { useAuth } from "modules/common/hooks";
import API from "api";

const RtoFormHome = ({ isUpdate }) => {
	const params = useParams();
	const [fetchState, setFetchState] = useState({ loading: true, error: false });
	const { setRtoFormDetails } = useAuth();

	const getFormData = async (formId) => {
		setFetchState({ loading: true });
		try {
			const data = await API.getSingleRtoForms(formId);
			console.log(data);
			setRtoFormDetails(data);
			setFetchState({ loading: false, error: false });
		} catch (e) {
			setFetchState({ loading: false, error: true });
		}
	};

	useEffect(() => {
		if (isUpdate && params.formId) {
			getFormData(params.formId);
		} else {
			setFetchState({ loading: false, error: false });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NavigationWrapper containerClassName="bg-_25253C lg:bg-white" title={"Spy for us"}>
			<div className="bg-_blue h-[120px] lg:hidden" />
			<div className="px-5 bg-white pt-[75.27px] lg:pt-[188px] relative">
				<DiamondsLg className="absolute pointer-events-none h-full w-full top-0 left-0" />
				<div className="lg:max-w-[1200px] flex flex-col items-center justify-center mx-auto">
					{fetchState.loading ? (
						<div />
					) : fetchState.error ? (
						<button
							className="text-[red]"
							onClick={() => {
								getFormData(params.formId);
							}}
						>
							There was an error. Please click to retry
						</button>
					) : (
						<StepForm isUpdate={isUpdate} />
					)}
				</div>
			</div>
		</NavigationWrapper>
	);
};

export default RtoFormHome;
