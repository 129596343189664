import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "classnames";
import { ReactComponent as BackArrow } from "modules/common/assets/icons/back-arrow.svg";
import { getEditedImageUrl } from "../utils/functions";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import PropertyLocation from "./PropertyLocation";

const PropertyImagesCarousel = ({ address, imageUrls, smallImg, showBackButton = true, backLink, phoneNumber }) => {
	const navigate = useNavigate();
	const [, setShowMap] = useState(true);

	const goToList = () => {
		navigate(backLink);
	};

	return (
		<div
			className="h-[551px] lg:h-[691px] rounded-10 bg-black relative 1400:mx-auto 1400:max-w-[1320px]"
			onMouseOut={() => setShowMap(true)}
			onMouseOver={() => setShowMap(false)}
		>
			<Carousel className="w-full" showThumbs={false}>
				{imageUrls.map((imageUrl, index) => (
					<div key={index} className="w-full h-[551px] lg:h-[591px] flex-shrink-0">
						<img
							src={getEditedImageUrl(imageUrl)}
							alt={`${index + 1}`}
							className="rounded-10 w-full h-full object-contain"
						/>
					</div>
				))}
			</Carousel>
			{showBackButton && (
				<button
					onClick={goToList}
					className="h-[53px] w-[53px] rounded-full bg-white flex items-center justify-center absolute top-6 left-[18px] 1436:hidden"
				>
					<BackArrow />
				</button>
			)}
			{address && (
				<div
					className={clsx(
						"cursor-pointer transition-all duration-300 fixed z-[2] right-[-60px] bottom-[20px] h-[150px] w-[150px] rounded-10 object-cover"
					)}
				>
					<PropertyLocation address={address} />
				</div>
			)}
			{phoneNumber && (
				<div className="absolute bottom-6 left-6 bg-white flex w-[100%] flex-wrap p-2 rounded-lg text-white">
					<p className="text-sm">Phone: {address}</p>
					<p className="text-sm">Phone: {phoneNumber}</p>
				</div>
			)}
		</div>
	);
};

export default PropertyImagesCarousel;
