import React from "react";
import clsx from "classnames";

const SectionHeader = ({ text, className }) => {
	return (
		<div className="flex flex-col space-y-[10px] lg:flex-shrink-0">
			<div className="h-[3px] bg-FEDF18 rounded-[3px]" />
			<span className={clsx("font-bold text-_black text-xl leading-[22px] px-1 lg:flex-shrink-0", className)}>
				{text}
			</span>
		</div>
	);
};

export default SectionHeader;
