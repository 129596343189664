import React, { useMemo } from "react";
import clsx from "classnames";
import { ReactComponent as SearchIcon } from "modules/common/assets/icons/search.svg";
import { ReactComponent as ArrowDownWhite } from "modules/properties/assets/icons/arrow-down-white.svg";
import { ReactComponent as ArrowDownGrey } from "modules/properties/assets/icons/arrow-down-grey.svg";
import PropertiesFilterPopUp from "./PropertiesFilterPopUp";
import CONSTANTS from "modules/common/utils/constants";
import PROPERTIES_CONSTANTS from "../utils/constants";

const { filterKeys, FILTER_TYPES } = PROPERTIES_CONSTANTS;

const maxFilterButtons = [
	{ text: "By Price", type: FILTER_TYPES.price },
	{ text: "By Availability", type: FILTER_TYPES.availability },
	{ text: "By Number of Bath", type: FILTER_TYPES.bathrooms },
	{ text: "By Number of Bed", type: FILTER_TYPES.bedrooms },
	{ text: "By Area", type: FILTER_TYPES.area },
	{ text: "By  Type", type: FILTER_TYPES.type },
];

const minimalFilterButtons = [
	{ text: "By Price", type: FILTER_TYPES.price },
	{ text: "By Number of Bath", type: FILTER_TYPES.bathrooms },
	{ text: "By Number of Bed", type: FILTER_TYPES.bedrooms },
	{ text: "By Area", type: FILTER_TYPES.area },
];

const PropertiesFilterButton = ({ isActive, text, onClick }) => {
	return (
		<button
			onClick={onClick}
			className={clsx(
				"py-[10px] px-[14px] bg-E8EBEC rounded-[41px] h-[36px] text-[#848484] flex space-x-[10px] items-center text-sm leading-4",
				{ "!bg-_25253C !text-white": isActive }
			)}
		>
			<span>{text}</span>
			{isActive ? <ArrowDownWhite /> : <ArrowDownGrey />}
		</button>
	);
};

const PropertiesFilter = ({
	state,
	dispatch,
	applyFilters,
	applyingFilters,
	isMinimal = false,
	textQuery,
	setTextQuery,
}) => {
	const filterButtons = isMinimal ? minimalFilterButtons : maxFilterButtons;

	const showPopup = () => {
		dispatch({ type: filterKeys.showPopup, value: true });
	};

	const hidePopup = () => {
		dispatch({ type: filterKeys.showPopup, value: false });
	};

	const filtersState = useMemo(
		() => ({
			[FILTER_TYPES.price]:
				state[filterKeys?.lastAppliedFilters]?.minPrice || state[filterKeys?.lastAppliedFilters]?.maxPrice,
			[FILTER_TYPES.availability]: false,
			[FILTER_TYPES.bathrooms]:
				state[filterKeys?.lastAppliedFilters] &&
				state[filterKeys?.lastAppliedFilters]?.bathroomCount !== CONSTANTS.ANY,
			[FILTER_TYPES.bedrooms]:
				state[filterKeys?.lastAppliedFilters] &&
				state[filterKeys?.lastAppliedFilters]?.bedroomCount !== CONSTANTS.ANY,
			[FILTER_TYPES.area]:
				state[filterKeys?.lastAppliedFilters]?.minArea || state[filterKeys?.lastAppliedFilters]?.maxArea,
			[FILTER_TYPES.type]: false,
		}),
		[state]
	);

	const activeFiltersCount = useMemo(
		() => Object.values(filtersState).filter((item) => !!item).length,
		[filtersState]
	);
	return (
		<div>
			<div className="flex items-center justify-between 1436:hidden">
				<button
					onClick={showPopup}
					className="border-[#E8EBEC] bg-white border rounded-[10px] min-h-[52px] py-3 px-5 flex items-center space-x-[9px]"
				>
					<span className="text-base leading-[18px] font-bold text-_25253C">Add Filters</span>
					<div className="h-[27px] w-[27px] rounded-full flex items-center justify-center bg-_25253C">
						<span className="font-bold text-[21px] leading-6 text-white">+</span>
					</div>
				</button>
				{/* <SearchIcon /> */}
			</div>
			<div className="1436:flex 1436:items-center 1436:justify-between h-[62px] border-E8EBEC bg-white border rounded-10 max-w-[1316px] mx-auto hidden pr-[10px] pl-[35px]">
				<button className="flex items-center space-x-[9px]" onClick={showPopup}>
					<span className="text-base text-_25253C leading-[18px] font-bold">Add Filters</span>
					{activeFiltersCount ? (
						<div className="h-[18px] w-[18px] bg-_25253C rounded-full flex items-center justify-center text-sm leading-base text-white font-bold">
							{activeFiltersCount}
						</div>
					) : null}
				</button>
				<div className="flex items-center space-x-4">
					{filterButtons.map((filterButton) => (
						<PropertiesFilterButton
							key={filterButton.type}
							text={filterButton.text}
							filterType={filterButton.type}
							onClick={showPopup}
							isActive={filtersState[filterButton.type]}
						/>
					))}
				</div>
				{!isMinimal ? (
					<div className="h-[37.12px] max-w-[270px] w-full border-[0.6px] rounded-[29.93px] border-_25253C bg-white px-[13.17px] flex items-center space-x-[5.99px]">
						<SearchIcon className="h-[22.75px] w-[22.75px] flex-shrink-0" />
						<input
							className="flex-1 text-[9.58px] md:text-sm text-_25253C border-none outline-none"
							placeholder="Search Property"
							value={textQuery}
							onChange={({ target: { value } }) => setTextQuery(value)}
						/>
					</div>
				) : (
					<button className="flex items-center space-x-[9px opacity-0 pointer-events-none">
						<span className="text-base text-_25253C leading-[18px] font-bold">Add Filters</span>
						{activeFiltersCount ? (
							<div className="h-[18px] w-[18px] bg-_25253C rounded-full flex items-center justify-center text-sm leading-base text-white font-bold">
								{activeFiltersCount}
							</div>
						) : null}
					</button>
				)}
			</div>
			<PropertiesFilterPopUp
				isVisible={state.showPopup}
				onClose={hidePopup}
				state={state}
				dispatch={dispatch}
				onApplyFunction={applyFilters}
				applyingFilters={applyingFilters}
			/>
		</div>
	);
};

export default PropertiesFilter;
