import { NavigationWrapper } from "modules/common/components";

const PrivacyData = [
	{
		id: 0,
		header: "PRIVACY POLICY",
		content: [
			"By accessing frontierstreet.us, providing information, or using our products and services, you agree to the terms outlined in this Privacy Policy.",
			"This policy covers the collection and use of personal data on frontierstreet.us, its mobile app, and data shared by business partners.",
			"Modifications may occur, and your continued use implies acceptance of changes.",
		],
	},
	{
		id: 1,
		header: "1. Introduction",
		content: [
			"This Privacy Policy explains how frontierstreet.us collects and uses personal data, in compliance with laws and regulations.",
			"Agreement to these terms is required to use our services.",
		],
	},
	{
		id: 2,
		header: "2. Personal Data You Provide to Us",
		content: [
			"Frontierstreet.us collects and uses personal data to enhance user experience, facilitate services, and comply with regulations.",
			"Agreement to these terms is necessary for using our services.",
		],
	},
	{
		id: 3,
		header: "3. Public Areas",
		content: [
			"Exercise caution when posting personal data in public areas of frontierstreet.us to protect your privacy.",
			"Users are responsible for safeguarding their accounts and contents.",
		],
	},
	{
		id: 4,
		header: "4. Automatic Information",
		content: [
			"Our servers may automatically collect automatic and/or aggregate information about your computer or electronic device when you visit this website and mobile app, including but without limited to the website and mobile app that referred you, Internet Protocol (“IP”) address, the type and configuration of the internet browser of your computer or electronic device, browsing preference such as language settings and font size, geo-location information and operating system of the computer or electronic device used for accessing our websites, and other usage and traffic information. The Company uses this automatic and/or aggregate information to analyze trends, gather and prepare statistics or demographic information and administer this website and mobile app so that we may provide improved web experiences to our users. Such automatic and/or aggregate information is not collected in a manner that would identify you personally. We may provide automatic and/or aggregate information, which are not personal data, to our partners about how our users, collectively use this website and mobile app. We may share this information with our partners, so that they may further understand the usage of their areas and this website and mobile app, so that they too can provide you with a better web experience. There may also be occasions when we are legally required to disclosure or provide access to our database in order to cooperate with police investigations or other legal proceedings or otherwise comply with any applicable laws or regulations, court order or lawful requests from any government authority. In those instances, the data is provided only for that purpose. For the avoidance of doubt, this Privacy Policy in no way restricts or limits our collection and use of such automatic and/or aggregate information.",
		],
	},
	{
		id: 5,
		header: "5. Use and Provision of Personal Data",
		content: [
			"Frontierstreet.us may collect, use, and provide personal data to enhance user experience, manage memberships, and administer gaming experiences.",
			"Other purposes include conducting contests, preventing fraud, enforcing privacy policies, and complying with laws.",
		],
	},
	{
		id: 6,
		header: "6. Cookies",
		content: [
			"In order to distinguish you from other users—especially other users on the same network as you— we would use cookies to track your specific computer or electronic device. Cookies are small computer text files that are sent from a website and mobile app to the browser of your computer or electronic device and are stored on your device. Generally, cookies do not recognize you personally and are not harmful to your device. Cookies are used by websites that you visit and browse to improve your website and mobile app browsing experience and provide the website and mobile app operators with non-personal data or analytical information to help improve the quality of their websites for their users’ website and mobile app browsing experience. For example, we use cookies to save your device’s browsing preferences and settings. For these reasons we require that you keep cookies enabled when using our websites. Please note that if you choose to disable cookies on your computer or electronic device, you may find that some of the features and functionality of this website and mobile app may not work properly. Additionally, our cookies may be accessible by third parties or provided by third parties including, for example, Google via Google AdSense on this website and mobile app. We do not have any control over and are not responsible or liable for such third parties’ collection and use of the cookies. Please refer to those third parties’ relevant policies regarding their collection and use of cookies for further information.",
		],
	},
	{
		id: 7,
		header: "7. Third Party Websites",
		content: [
			"This website and mobile app may contain links to third party websites, advertisers or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party websites, advertisers or services or their use of your personal data. If you access a third party website and mobile app from this website and mobile app, you do so at your own risk and you understand that neither this Privacy Policy nor our Terms & Conditions apply to your use of such sites. We would encourage you to review the privacy policies and terms of use of any third party website and mobile app, advertisers or service that you visit or use before providing your personal data to those third parties, and we shall not be liable in relation to your activities on or with those other third party websites, advertisers or services.   ",
		],
	},
	{
		id: 8,
		header: "8. Third Party Access to Your Personal Data",
		content: [
			"Without prejudice to the provisions in Clause “Use and Provision of Personal Data” above, we endeavor to do our best to protect your personal data from being accessed by third parties. Unless the business is acquired, transferred or sold, we will not share any user data to the task third party. At the same time, in any cooperation with any third party, we will not provide original user tag data, social platform data and other data, but cooperate in a way that hides and protects the privacy of users, and has the ability to terminate sharing at any time.",
			"For internal risk control, we have an independent operation and maintenance team, developers cannot read user data in the production environment and the management system has a strict permission mechanism. Unless the customer service personnel who directly communicate with the user clearly obtained authorization from the customer, can access the data of the customer he served, no one else can obtain the user's account data and social platform data from any way.",
		],
	},
	{
		id: 9,
		header: "9. How You Can Request Data Deletion",
		content: [
			"Please email us via jack.crenshaw@frontierstreet.us and provide your username, sign-in method, and a screenshot of your account page. Our customer service will deal with your request as soon as possible",
		],
	},
	{
		id: 10,
		header: "10. Amendments",
		content: [
			"We may amend this Privacy Policy from time to time, and the amended version will be posted on this website and mobile app in place of the old version. The changes in this Privacy Policy become effective as soon as the changes are published to this website and mobile app. If you continue to use the website and mobile app after the changes become effective, it will signify your agreement to be bound by such modifications or revisions",
			"If you do not agree with the revised/updated Privacy Policy, you must immediately cease using this website and mobile app and service",
		],
	},
	{
		id: 11,
		header: "11. Validity",
		content: [
			"Severance of any provision of this Privacy Policy is held to be invalid or unenforceable for any reason by a court of competent jurisdiction, such provision shall be deemed to be severed from this Privacy Policy. Such unenforceability or invalidity shall not in any way affect the enforceability and/or validity of the remaining provisions of this Privacy Policy which shall continue in full force and effectacy Policy is held invalid, it does not affect the validity of the remaining provisions.",
		],
	},
	{
		id: 12,
		header: "12. Contact",
		content: [
			"For inquiries about your rights under this Privacy Policy, contact jack.crenshaw@frontierstreet.us.",
			"You have the right to request access to and/or correction of your personal data.",
			"In case of inconsistency between English and other language versions, the English version prevails.",
			"Last updated on 29th November 2023.",
		],
	},
];

const PrivacyPage = () => {
	return (
		<NavigationWrapper containerClassName=" lg:bg-white">
			<div className="bg-_blue h-[120px] lg:hidden"></div>
			<div className=" px-[20px] py-[60px] md:py-[150px]  bg-white">
				{PrivacyData.map(({ id, header, content }) => (
					<div
						key={id}
						className="flex flex-col justify-start w-1/1 md:w-[1000px] m-auto py-4 md:py-3 px-3 md:px-8"
					>
						<h1 className="font-bold text-[20px] gradient-text md:text-[32px] leading-[29px] md:leading-[52px] text-left md:mt-[20px] mb-[32px] md:mb-[0px]">
							{header}
						</h1>
						{content.map((paragraph, index) => (
							<p
								key={index}
								className="text-[15px] md:text-[16px] font-[400] text-_25253C text-left md:my-[10px] leading-[22px]"
							>
								{paragraph}
							</p>
						))}
					</div>
				))}
			</div>
		</NavigationWrapper>
	);
};

export default PrivacyPage;
