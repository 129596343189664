import API from "api";
import { NavigationWrapper } from "modules/common/components";
import CONSTANTS from "modules/common/utils/constants";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const getEditorState = (initialContent) => {
	const blocksFromHTML = htmlToDraft(initialContent);
	const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
	return EditorState.createWithContent(contentState);
};

const BlogDetail = () => {
	const [data, setData] = useState(null);
	const { blogId } = useParams();
	const navigate = useNavigate();

	const getBlogDetail = useCallback(async () => {
		try {
			const response = await API.getBlogDetail(blogId);
			setData(response);
		} catch (e) {
			navigate(CONSTANTS.routes.blog);
		}
	}, [blogId, navigate]);

	useEffect(() => {
		getBlogDetail();
	}, [getBlogDetail]);

	return (
		<NavigationWrapper>
			<div className="bg-_blue h-[120px] lg:hidden" />
			<div className="pt-8 px-5 lg:pt-[202px] pb-20 bg-white 1300:max-w-[1000px] 1300:mx-auto">
				{data && (
					<h2 className="mb-[10px] lg:mb-[15px] font-bold text-[20px] text-wrap leading-[35.2px] text-_25253C lg:text-[48px] lg:leading-[52.2px]">
						{data?.title}
					</h2>
				)}
				{data && (
					<Editor editorState={getEditorState(data.content || "")} toolbarHidden={true} readOnly={true} />
				)}
			</div>
		</NavigationWrapper>
	);
};

export default BlogDetail;
