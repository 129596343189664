export const navigateToAdminRoute = (navigate, route) => {
	navigate("/admin/" + route);
};

const CONSTANTS = {
	routes: {
		onboarding:
			"djkanviqif941f0c9jscsomcsck3491fj409fjq0ecjcosaq49r09j1fcwkcmasl91904jv0e9jajaoncdsn1f19rj043jcnaci1of0cjdcascldeqe/:token",
		home: "/",
		sellYourHouse: "/sell-your-house",
		threeD: "/tours/list",
		spyForUs: "/spy-for-us",
		propertiesList: "/properties/list",
		pawnList: "/pawnProperties/list",
		rtoFormHome: "/rtow-lease-form",
		rtoFormSubmissions: "/rtow-lease-form-submissions",
		tourList: "/properties/list",
		privacy: "/privacy-policy",
		terms: "/terms-of-service",
		spyForm: "/spy-for-us/form",
		contact: "/contact-us",
		propertyDetail: (propertyId) =>
			propertyId ? `/properties/${propertyId}/detail` : "/properties/:propertyId/detail",
		tourDetail: (tourId) => (tourId ? `/tours/${tourId}/detail` : "/tours/:tourId/detail"),
		blogDetail: (blogId) => (blogId ? `/blogs/${blogId}/detail` : `/blogs/:blogId/detail`),
		rtoFormDetail: (formId) => (formId ? `/rtow-lease-form/${formId}/detail` : `/rtow-lease-form/:formId/detail`),
		rtoFormUpdate: (formId) => (formId ? `/rtow-lease-form/${formId}/update` : `/rtow-lease-form/:formId/update`),

		blog: "/blog",
		login: "/login",
		register: "/register",
		admin: {
			root: "/admin",
			rtoFormList: "rtow-lease-form/list",
			addListing: "add-listing",
			addTours: "add-tours",
			properties: "properties",
			rtowForms: "rtowforms",
			deleteProperties: "delete-properties",
			messages: "messages",
			fairCashApplications: "fair-cash-applications",
			spyFormSubmissions: "spy-form-submissions",
			inviteUser: "invite",
			addBlog: "add-blog",
			rtoFormDetail: (formId) => (formId ? `rtow-lease-form/${formId}/detail` : `rtow-lease-form/:formId/detail`),
		},
	},
	STORAGE_KEYS: {
		WANTS_TO_VISIT_FAQS: "WANTS_TO_VISIT_FAQS",
		AUTH_DATA: "AUTH_DATA",
		USER_DATA: "USER_DATA",
		RTOFORM_DATA: "RTOFORM_DATA",
	},
	ANY: "Any",
	BLOG: {
		PRODUCTIVITY: "Productivity",
		TIPS_AND_TRICKS: "Tips & Tricks",
		MUST_READ: "Must Read",
	},
};

export default CONSTANTS;

export const initialRTOFormState = {
	contactDetails: {
		name: "",
		email: "",
		phoneNumber: "",
		fullAddressOfProperty: "",
	},
	importantDetails: {
		homesteadExemption: "",
		currentYearlyTaxAmount: "",
		loan1Payoff: "",
		loan1Reinstatement: "",
		loan1CurrentPayment: "",
		loan2Payoff: "",
		loan2Reinstatement: "",
		loan2CurrentPayment: "",
		cashToSeller: "",
		rentBackAmount: "",
		resalePrice: "",
		downPaymentForResale: "",
		dropBoxLink: "",
	},
	propertyDetails: {
		notesAboutSeller: "",
		composition: "",
		acreage: "",
		totalFinishedSquareFootageOfHouse: "",
		propertyAmenities: "",
		homesteadExemptionCurrentlyInPlace: "",
		yearlyPropertyTaxes: "",
		linkToProperty: "",
		monthlyRentOfferedForLeasePurchase: "",
		estimatedWorthAsIs: "",
		otherNotesAboutProperty: "",
		zillowEstimate: "",
	},
	mortgageDetails: {
		firstMortgageExists: true,
		payoffAmount: "",
		reInstatementAmount: "",
		interestRateOfLoan: "",
		totalMonthlyPayment: "",
		approximatePrincipalPaidDownMonthly: "",
		durationLeftOnLoanInYears: "",
		otherMortgagesAgainstPropertyExist: true,
		additionalEncumbrances: "",
	},
	purchaseRelatedDetails: {
		wholesaleFeeForBuyer: "",
		realEstateAgentFee: "",
		grossCashToSeller: "",
		buyerNeedsToPayForFurnitureOrMovingExpenses: false,
		buyerExpenses: "",
	},
	leasePurchaseDetails: {
		priceToBeRepurchased: "",
		realEstateAgentFeeToSeller: "",
		otherSellerExpensesForRepurchase: "",
		monthsLeasedBeforeRepurchase: "",
		downPaymentBuyerWillingToPay: "",
		documents: [],
	},
};
