import axios from "axios";
import CONSTANTS from "modules/common/utils/constants";
import { removeFromLS } from "modules/common/utils/functions";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_API_URL + "/api/v1";

const baseAxios = axios.create({
	baseURL,
});

baseAxios.interceptors.response.use(
	function (response) {
		if (response?.data) {
			if (response?.data?.message) {
				toast.success(response.data.message, { duration: 8000 });
			}
			if (response?.data?.data && !response?.data?.metadata) {
				return response.data.data;
			}
			return response.data;
		}
		return response;
	},
	function (error) {
		if (error?.response?.status === 401) {
			toast.error("Session expired....");
			removeFromLS(CONSTANTS.STORAGE_KEYS.AUTH_DATA);
			window.location.reload();
			return Promise.reject(error);
		}
		if (error?.response?.data?.error) {
			toast.error(error.response.data.error, { duration: 8000 });
		} else {
			toast.error("There was an error. Please check your network connection");
		}
		return Promise.reject(error);
	}
);

export const setAPIToken = (token) => (baseAxios.defaults.headers["x-auth-token"] = token);

export const removeToken = () => delete baseAxios.defaults.headers["x-auth-token"];

const API = {
	applyForFairCash: (data) => baseAxios.post("/fair-cash-application", data),
	sendContactMessage: (data) => baseAxios.post("/contact-form-submission", data),
	applyForSpyForm: (payload) =>
		baseAxios.post("/spy-form", payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
	getProperties: (page) => baseAxios.get("/properties", { params: { page } }),
	getTours: (page) => baseAxios.get("/tours", { params: { page } }),
	searchProperties: (query, page) => baseAxios.get("/properties/find", { params: { ...query, page } }),
	searchRtoForms: (query) => baseAxios.get("/rtow-lease-form", { params: { ...query } }),
	searchTours: (query, page) => baseAxios.get("/tours/find", { params: { ...query, page } }),
	getSingleProperty: (propertyId) => baseAxios.get(`/properties/detail?id=${propertyId}`),
	getSingleTour: (tourId) => baseAxios.get(`/tours/detail?id=${tourId}`),
	login: (payload) => baseAxios.post("/user/login", payload),
	register: (payload) => baseAxios.post("/user/register", payload),
	getPopularBlogs: () => baseAxios.get("/user/popular-blogs"),
	getBlogsForCategory: (params) => baseAxios.get("/user/blog", { params }),
	getBlogDetail: (blogId) => baseAxios.get(`/user/blogs/${blogId}/detail`),
	getAllRtoForms: (page) => baseAxios.get("/rtow-lease-form", { params: { page } }),
	getSingleRtoForms: (formId) => baseAxios.get(`/rtow-lease-form/${formId}/detail`),

	admin: {
		verifyToken: (payload) => baseAxios.post("/user/invite/verify", payload),
		sendEmail: (payload) => baseAxios.post("/email/send-email", payload),

		onboard: (payload) => baseAxios.post("/user/onboard", payload),
		listProperty: (payload) =>
			baseAxios.post("/properties", payload, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
		listTour: (payload) =>
			baseAxios.post("/tours", payload, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),

		getAllListedProperties: (page) => baseAxios.get("/user/properties", { params: { page } }),
		getAllListedTours: (page) => baseAxios.get("/user/tours", { params: { page } }),
		deleteListedProperty: (property) => baseAxios.delete("/user/properties", { params: { property } }),
		searchListedProperties: (query, page) => baseAxios.get("/user/properties/find", { params: { ...query, page } }),
		getAllFairCashApplications: (query, page) =>
			baseAxios.get("/fair-cash-applications", { params: { query, page } }),
		getAllSpyFormApplications: (query, page) => baseAxios.get("/spy-form-submissions", { params: { query, page } }),

		starFairCashApplication: (payload) => baseAxios.patch("/fair-cash-applications/star", payload),
		starSpyFormSubmission: (payload) => baseAxios.patch("/spy-form-submissions/star", payload),
		searchWithAddress: (address) => baseAxios.get("/properties/address-search", { params: { address } }),
		listPropertyWithAPI: (payload) => baseAxios.post("/properties/list-with-api", payload),
		editPropertyStatus: (payload) => baseAxios.patch("/properties", payload),
		inviteNewAdmin: (payload) => baseAxios.post("/user/invite", payload),
		deleteBlog: (blogId) => baseAxios.delete(`/user/blog/${blogId}`),
		addNewBlog: (payload) =>
			baseAxios.post("/user/blog", payload, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
		attachImages: (payload) =>
			baseAxios.post("/properties/attach-images", payload, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
	},
	submitRTOForm: (payload) => {
		return baseAxios.post("/rtow-lease-form", payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	},
	getRTOSubmissions: (query, page) => {
		return baseAxios.get("/rtow-lease-form/submissions", {
			params: { query, page },
		});
	},
	updateRTOSubmission: (submissionId, payload) => {
		return baseAxios.patch(`/rtow-lease-form/${submissionId}/update`, payload, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	},
	removeRTOSubmissionMedia: (submissionId, payload) => {
		return baseAxios.patch(`/rtow-lease-form/${submissionId}/media`, payload);
	},
	submitSubmissionForReview: (submissionId) => {
		return baseAxios.patch(`/rtow-lease-form/${submissionId}/submit-for-review`);
	},

	updateRTOFormStatus: (submissionId, payload) => {
		return baseAxios.patch(`/rtow-lease-form/${submissionId}/status`, payload, {
			headers: {
				"Content-Type": "application/json", // Correct content type for JSON
			},
		});
	},
};

export const verifyTokenCall = (token) =>
	axios.get(`${baseURL}/user/verify`, {
		headers: {
			"x-auth-token": token,
		},
	});

export default API;
