import React, { useState } from "react";
import clsx from "classnames";
import { useDropzone } from "react-dropzone";
import { Button, Input, Select } from "modules/common/components";
import { NumericFormat } from "react-number-format";
import { ReactComponent as Star } from "modules/admin/assets/icons/star.svg";
import { ReactComponent as Location } from "modules/properties/assets/icons/location.svg";
import { ReactComponent as Bathroom } from "modules/properties/assets/icons/bathroom.svg";
import { ReactComponent as Bedroom } from "modules/properties/assets/icons/bedroom.svg";
import { ReactComponent as Area } from "modules/properties/assets/icons/area.svg";
import { pluralize, validators } from "modules/common/utils/functions";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDebouncedLoader, useWindowSize } from "modules/common/hooks";
import API from "api";
import { americanStates, propertyTypes } from "modules/admin/utils/constants";

const defaultValues = {
	tag: "",
	bathroomCount: "",
	bedroomCount: "",
	area: 0,
	price: 0,
	type: { label: "", value: "" },
	status: "Available",
	rating: "",
	description: "",
	threeDFrame: "",
	location: {
		streetAddress: "",
		neighborhood: "",
		city: "",
		zipCode: "",
		state: { label: "", value: "" },
	},
};

const AddTours = () => {
	const { width } = useWindowSize();
	const [loading, setLoading] = useState(false);
	const isLoading = useDebouncedLoader(loading);
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
		clearErrors,
	} = useForm({
		defaultValues,
	});

	const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".png"],
		},
		maxFiles: 50,
		maxSize: 3145728,
	});

	const rating = watch("rating");

	const onSubmit = async (data) => {
		if (!acceptedFiles.length) {
			toast.error("Please upload at least one picture of the property");
			return;
		}
		setLoading(true);
		const { location, ...rest } = data;
		location.state = location.state.value;
		rest.type = rest.type.value;
		const formData = new FormData();
		Object.keys(rest).forEach((key) => {
			formData.append(key, rest[key]);
		});
		Object.keys(location).forEach((key) => {
			formData.append(`location[${key}]`, location[key]);
		});
		acceptedFiles.forEach((acceptedFile) => {
			formData.append("pictures", acceptedFile);
		});
		try {
			await API.admin.listTour(formData);
			setLoading(false);
			reset(defaultValues);
			window.scroll(0, 0);
			clearErrors(["price", "area"]);
			acceptedFiles.splice(0, acceptedFiles.length);
			inputRef.current.value = "";
		} catch (e) {
			setLoading(false);
		}
	};
	return (
		<div className="pt-5 1010:pt-[66px] 1010:py-[47px]  1010:pl-[66px]">
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="flex pb-5 868:flex-row 868:space-x-[26px] 868:space-y-0 flex-col space-y-[11px]"
			>
				<div className="flex-1 868:max-w-[672px]">
					<div className="flex flex-col space-y-[11px] mb-7">
						<span className="text-base leading-6 text-_25253C">Add Photos</span>
						<div
							{...getRootProps()}
							className="bg-white relative h-[162px] border border-E8EBEC rounded-10 flex flex-col items-center justify-center"
						>
							<input {...getInputProps()} />
							<div className="h-6 w-6 rounded-full flex items-center justify-center shadow-[1px_1px_4px_0px_#D9D9D9] mb-[9px]">
								<span>+</span>
							</div>
							<div>
								<h3 className="text-base leading-6 text-[#848484] text-center">
									{acceptedFiles.length
										? `${acceptedFiles?.length} ${pluralize(
												acceptedFiles?.length,
												"picture"
										  )} uploaded`
										: "Upload Pictures here"}
								</h3>
								<p className="text-E8EBEC italic text-[10px] text-center">
									You can add up to 50 pictures
								</p>
							</div>
						</div>
					</div>

					<div className="flex flex-col 868:space-x-[25px] 868:flex-row 868:space-y-0 space-y-[11px]">
						<div className="flex flex-col space-y-[11px] flex-1">
							<span className="text-base leading-none text-_25253C">Add Address/ Property Title</span>
							<Controller
								control={control}
								name="tag"
								rules={{
									required: true,
									validate: (v) => validators.validateString(v),
								}}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										className={
											"!h-8 !text-base placeholder:italic placeholder:text-E8EBEC !border !border-E8EBEC !border-solid"
										}
										placeholder="Name"
										value={value}
										onChange={onChange}
										hasError={errors.tag}
										ref_={ref}
									/>
								)}
							/>
						</div>

						<div className="flex flex-col space-y-[11px] flex-shrink-0">
							<span className="text-base leading-none 868:ml-auto text-_25253C">Ratings</span>
							<div className="flex space-x-[3px] items-center">
								<Controller
									control={control}
									name="rating"
									rules={{
										required: true,
										validate: (v) => Number.isInteger(v),
									}}
									render={({ field: { onChange, value, ref } }) => (
										<NumericFormat
											decimalScale={0}
											allowNegative={false}
											value={value}
											onValueChange={(values) => onChange(values.floatValue)}
											isAllowed={(values) => {
												if (!values.value) return true;
												const { floatValue } = values;
												return floatValue < 6 && floatValue > 0;
											}}
											className={clsx(
												"h-8 w-[39px] text-center bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base",
												{ "!border-[red]": errors.rating }
											)}
										/>
									)}
								/>
								<div className="flex space-x-[2px]">
									{Array(5)
										.fill(0)
										.map((item, index) => {
											const isFilled = (rating || 0) > index;
											return <Star key={index} className={isFilled ? "filled-star" : ""} />;
										})}
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col pt-5 space-y-[11px] flex-1">
						<span className="text-base leading-none text-_25253C">3D Frame</span>
						<Controller
							control={control}
							name="threeDFrame"
							rules={{
								required: true,
								validate: (v) => validators.validateString(v),
							}}
							render={({ field: { onChange, value, ref } }) => (
								<Input
									className={
										"!h-8 !text-base placeholder:italic placeholder:text-E8EBEC !border !border-E8EBEC !border-solid"
									}
									placeholder="Frame Url"
									value={value}
									onChange={onChange}
									hasError={errors.threeDFrame}
									ref_={ref}
								/>
							)}
						/>
					</div>
					<div className="mt-[21px] flex flex-col space-y-[11px]">
						<span className="text-base leading-none text-_25253C">Property Type</span>
						<Controller
							control={control}
							name="type"
							rules={{ required: true, validate: (v) => !!v.value }}
							render={({ field: { ref, ...rest } }) => (
								<Select
									placeholder="Select a property type"
									options={propertyTypes}
									hasError={!!errors?.type}
									ref_={ref}
									{...rest}
								/>
							)}
						/>
					</div>
					<div className="mt-[21px] flex flex-col space-y-[11px]">
						<span className="text-base leading-none text-_25253C">Description</span>
						<Controller
							control={control}
							name="description"
							rules={{
								required: true,
								validate: (v) => validators.validateString(v),
							}}
							render={({ field: { onChange, value, ref } }) => (
								<textarea
									className={clsx(
										"resize-none h-[263px] w-full border border-E8EBEC rounded-10 outline-none px-[12.69px] lg:px-[21.57px] py-3 placeholder:text-E8EBEC placeholder:italic",
										{ "border-[red]": errors.description }
									)}
									placeholder="Describe listing"
									value={value}
									onChange={onChange}
									ref={ref}
								/>
							)}
						/>
					</div>
					{width >= 868 && (
						<div className="mt-[45px] flex items-center justify-center">
							<Button text={"Publish Tour"} type="submit" loading={isLoading} />
						</div>
					)}
				</div>
				<div className="w-full 868:w-[345px] flex-shrink-0 self-start">
					<div className="flex flex-col space-y-[11px]">
						<span className="hidden text-base leading-6 opacity-0 pointer-events-none 868:block text-_25253C">
							Add Photos
						</span>
						<div className="bg-white border border-E8EBEC pt-10 pb-[23px] px-9 rounded-10">
							<div className="flex flex-col space-y-[5px]">
								<span className="text-base leading-6 text-_25253C">
									Add <span className="font-bold">Price</span>
								</span>
								<Controller
									control={control}
									name="price"
									rules={{ required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<NumericFormat
											value={value}
											onValueChange={(values) => onChange(values.floatValue)}
											thousandSeparator=","
											prefix="$"
											decimalScale={2}
											allowNegative={false}
											isAllowed={(values) => {
												if (!values.value) return true;
												const { floatValue } = values;
												return floatValue > 0;
											}}
											className={clsx(
												"h-[39px] w-[93px] bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base",
												{ "!border-[red]": !!errors.price }
											)}
										/>
									)}
								/>
							</div>
							{/* location */}
							<div className="flex flex-col space-y-[5px] mt-[26.25px]">
								<div className="flex items-center space-x-1">
									<Location className="w-4 h-4" />
									<span className="text-base leading-6 text-_25253C">Location</span>
								</div>
								<div className="flex flex-col space-y-[5px]">
									<Controller
										control={control}
										name="location.streetAddress"
										rules={{
											required: true,
											validate: (v) => validators.validateString(v),
										}}
										render={({ field: { onChange, value, ref } }) => (
											<Input
												onChange={onChange}
												value={value}
												ref_={ref}
												className="!h-[39px] !text-base placeholder:italic placeholder:text-E8EBEC !border !border-E8EBEC !border-solid"
												placeholder="Street Address"
												hasError={errors?.location?.streetAddress}
											/>
										)}
									/>
									<Controller
										control={control}
										name="location.neighborhood"
										rules={{
											required: true,
											validate: (v) => validators.validateString(v),
										}}
										render={({ field: { onChange, value, ref } }) => (
											<Input
												onChange={onChange}
												value={value}
												ref_={ref}
												className="!h-[39px] !text-base placeholder:italic placeholder:text-E8EBEC !border !border-E8EBEC !border-solid"
												placeholder="Neighborhood"
												hasError={errors?.location?.neighborhood}
											/>
										)}
									/>
									<Controller
										control={control}
										name="location.city"
										rules={{
											required: true,
											validate: (v) => validators.validateString(v),
										}}
										render={({ field: { onChange, value, ref } }) => (
											<Input
												onChange={onChange}
												value={value}
												ref_={ref}
												className="!h-[39px] !text-base placeholder:italic placeholder:text-E8EBEC !border !border-E8EBEC !border-solid"
												placeholder="City"
												hasError={!!errors?.location?.city}
											/>
										)}
									/>
									<Controller
										control={control}
										name="location.zipCode"
										rules={{
											required: true,
											validate: (v) => validators.validateString(v),
										}}
										render={({ field: { onChange, value, ref } }) => (
											<Input
												onChange={onChange}
												value={value}
												ref_={ref}
												className="!h-[39px] !text-base placeholder:italic placeholder:text-E8EBEC !border !border-E8EBEC !border-solid"
												placeholder="Zip Code"
												hasError={!!errors?.location?.zipCode}
											/>
										)}
									/>
									<Controller
										control={control}
										name="location.state"
										rules={{ required: true, validate: (v) => !!v.value }}
										render={({ field: { ref, ...rest } }) => (
											<Select
												placeholder="Select a state"
												options={americanStates}
												hasError={!!errors?.location?.state}
												ref_={ref}
												{...rest}
											/>
										)}
									/>
								</div>
							</div>
							<div className="mt-[17px]">
								<div className="flex items-center justify-between">
									<div className="flex items-center space-x-2">
										<Bathroom className="md:h-[20.69px] md:w-[20.69px]" />
										<Controller
											control={control}
											name="bathroomCount"
											rules={{ required: true, validate: (v) => v > 0 }}
											render={({ field: { onChange, value, ref } }) => (
												<NumericFormat
													value={value}
													onValueChange={(values) => onChange(values.floatValue)}
													decimalScale={0}
													allowNegative={false}
													isAllowed={(values) => {
														if (!values.value) return true;
														const { floatValue } = values;
														return floatValue > 0;
													}}
													className={clsx(
														"h-8 w-[39px] bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base text-center",
														{ "!border-[red]": errors?.bathroomCount }
													)}
												/>
											)}
										/>
									</div>
									<div className="flex items-center space-x-2">
										<Bedroom className="md:h-[20.69px] md:w-[20.69px]" />
										<Controller
											control={control}
											name="bedroomCount"
											rules={{ required: true, validate: (v) => v > 0 }}
											render={({ field: { onChange, value, ref } }) => (
												<NumericFormat
													value={value}
													onValueChange={(values) => onChange(values.floatValue)}
													decimalScale={0}
													allowNegative={false}
													isAllowed={(values) => {
														if (!values.value) return true;
														const { floatValue } = values;
														return floatValue > 0;
													}}
													className={clsx(
														"h-8 w-[39px] bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base text-center",
														{ "!border-[red]": errors?.bedroomCount }
													)}
												/>
											)}
										/>
									</div>
									<div className="flex items-center space-x-2">
										<Area className="md:h-[20.69px] md:w-[20.69px]" />
										<Controller
											control={control}
											name="area"
											rules={{ required: true }}
											render={({ field: { onChange, value, ref } }) => (
												<NumericFormat
													value={value}
													onValueChange={(values) => onChange(values.floatValue)}
													decimalScale={0}
													thousandSeparator=","
													allowNegative={false}
													isAllowed={(values) => {
														if (!values.value) return true;
														const { floatValue } = values;
														return floatValue > 0;
													}}
													className={clsx(
														"h-8 w-[62px] bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base",
														{ "!border-[red]": !!errors?.area }
													)}
												/>
											)}
										/>
										<span className="!ml-[5px] text-base text-[#848484]">ft²</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{width < 868 && (
					<div className="flex items-center justify-center">
						<Button text={"Publish Tour"} type="submit" loading={isLoading} />
					</div>
				)}
			</form>
		</div>
	);
};

export default AddTours;
