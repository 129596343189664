import React, { useMemo } from "react";
import clsx from "classnames";
import { NumericFormat } from "react-number-format";
import { ReactComponent as ExitIcon } from "modules/common/assets/icons/exit-grey.svg";
import { Button } from "modules/common/components";
import CONSTANTS from "modules/common/utils/constants";
import PROPERTIES_CONSTANTS from "../utils/constants";
import toast from "react-hot-toast";
import { useDebouncedLoader } from "modules/common/hooks";

const { filterKeys } = PROPERTIES_CONSTANTS;

const PropertiesFilterButtonOptions = ({ selectedValue, onSelect }) => {
	const options = useMemo(() => [CONSTANTS.ANY, "1", "2", "3", "4", "5+"], []);
	return (
		<div className="flex items-center space-x-[5px]">
			{options.map((option, index) => (
				<button
					onClick={() => onSelect(option)}
					key={index}
					className={clsx(
						"h-[26px] transition-all px-[17px] flex items-center justify-center bg-[#E8EBEC] text-_25253C rounded-[14px]",
						{
							"!text-white !bg-_25253C": option === selectedValue,
						}
					)}
				>
					{option}
				</button>
			))}
		</div>
	);
};

const PropertiesFilterPopUp = ({ isVisible, onClose, state, dispatch, onApplyFunction, applyingFilters }) => {
	const isLoading = useDebouncedLoader(applyingFilters);
	const changeValue = (key, value) => {
		dispatch({ type: key, value });
	};

	const clearAll = () => {
		onApplyFunction({}, () => {
			dispatch({ type: "clear_all" });
		});
	};

	const validateFilters = () => {
		let errors = [];
		const { maxPrice, minPrice, maxArea, minArea } = state;
		if (minPrice && !maxPrice) {
			return ["Max price is required"];
		}
		if (minPrice && maxPrice < minPrice) {
			return ["Max price should be higher than min price"];
		}
		if (minArea && !maxArea) {
			return ["Max area is required"];
		}
		if (minArea && maxArea < minArea) {
			return ["Max area should be higher than min area"];
		}
		return errors;
	};

	const onApply = () => {
		const { lastAppliedFilters, ...rest } = state;
		const errors = validateFilters();
		if (errors.length) {
			toast.error(errors.join("\n"));
			return;
		}
		onApplyFunction(rest, () => {
			const newState = {
				...state,
				lastAppliedFilters: { ...rest },
			};
			dispatch({ type: "set_all", value: newState });
			onClose();
		});
	};

	const closeWithoutSavingChanges = () => {
		if (state.lastAppliedFilters) {
			const { lastAppliedFilters } = state;
			const newState = {
				...lastAppliedFilters,
				lastAppliedFilters,
				showPopup: false,
			};
			dispatch({ type: "set_all", value: newState });
		} else {
			const newState = {
				[filterKeys.showPopup]: false,
				[filterKeys.minPrice]: "",
				[filterKeys.maxPrice]: "",
				[filterKeys.bathroomCount]: CONSTANTS.ANY,
				[filterKeys.bedroomCount]: CONSTANTS.ANY,
				[filterKeys.minArea]: "",
				[filterKeys.maxArea]: "",
			};

			dispatch({ type: "set_all", value: newState });
		}
	};

	return (
		<div
			className={clsx(
				"bg-black/[.6] px-5 fixed top-0 left-0 h-screen w-full pt-[20px] md:pt-[150px] flex justify-center transition-all pointer-events-none opacity-0 z-[4]",
				{
					"!opacity-100 !pointer-events-auto": isVisible,
				}
			)}
			role="presentation"
			onClick={closeWithoutSavingChanges}
		>
			<div
				className="max-w-[348px] md:w-[348px] bg-white px-[13px] pt-8 pb-4 self-start rounded-10"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="pb-[19px] px-[15px] border-b-[0.5px] border-b-[#B9BEBF]">
					<div className="flex items-center justify-between">
						<h3 className="text-_25253C text-base leading-[18px] font-bold">Filters</h3>
						<ExitIcon onClick={closeWithoutSavingChanges} className="cursor-pointer" />
					</div>
				</div>
				<div className="pt-[30px] px-[15px]">
					{/* price */}
					<div className="flex flex-col space-y-[19px] mb-[23px]">
						<h3 className="text-base leading-[18px] font-bold text-_25253C">By Price</h3>
						<div className="flex space-x-6">
							<div className="h-11 flex-1 flex flex-col border-[0.5px] border-[#C9C7C7] rounded-10 py-[6px] px-3">
								<span className="text-[10px] leading-4 text-black">Min Price</span>
								<NumericFormat
									prefix="$ "
									onValueChange={(values) => {
										changeValue(filterKeys.minPrice, values.floatValue);
									}}
									value={state[filterKeys.minPrice]}
									thousandSeparator=","
									className="text-[10px] text-black font-bold outline-none border-none w-full"
									min={1}
								/>
							</div>
							<div className="h-11 flex-1 flex flex-col border-[0.5px] border-[#C9C7C7] rounded-10 py-[6px] px-3">
								<span className="text-[10px] leading-4 text-black">Max Price</span>
								<NumericFormat
									onValueChange={(values) => {
										changeValue(filterKeys.maxPrice, values.floatValue);
									}}
									value={state[filterKeys.maxPrice]}
									prefix="$ "
									thousandSeparator=","
									className="text-[10px] text-black font-bold outline-none border-none w-full"
								/>
							</div>
						</div>
					</div>
					{/* bath */}
					<div className="flex flex-col space-y-[11px] mb-[30px]">
						<span className="text-base leading-[18px] text-black font-bold">By Bath</span>
						<PropertiesFilterButtonOptions
							selectedValue={state[filterKeys.bathroomCount]}
							onSelect={(val) => changeValue(filterKeys.bathroomCount, val)}
						/>
					</div>
					{/* bed */}
					<div className="flex flex-col space-y-[11px] mb-[30px]">
						<span className="text-base leading-[18px] text-black font-bold">By Bed</span>
						<PropertiesFilterButtonOptions
							selectedValue={state[filterKeys.bedroomCount]}
							onSelect={(val) => changeValue(filterKeys.bedroomCount, val)}
						/>
					</div>
					{/* area */}
					<div className="flex flex-col space-y-[19px] mb-[23px]">
						<h3 className="text-base leading-[18px] font-bold text-_25253C">By Area (/ft²)</h3>
						<div className="flex space-x-6">
							<div className="h-11 flex-1 flex flex-col border-[0.5px] border-[#C9C7C7] rounded-10 py-[6px] px-3">
								<span className="text-[10px] leading-4 text-black">Min Area</span>
								<NumericFormat
									onValueChange={(values) => {
										changeValue(filterKeys.minArea, values.floatValue);
									}}
									value={state[filterKeys.minArea]}
									thousandSeparator=","
									className="text-[10px] text-black font-bold outline-none border-none w-full"
								/>
							</div>
							<div className="h-11 flex-1 flex flex-col border-[0.5px] border-[#C9C7C7] rounded-10 py-[6px] px-3">
								<span className="text-[10px] leading-4 text-black">Max Area</span>
								<NumericFormat
									thousandSeparator=","
									onValueChange={(values) => {
										changeValue(filterKeys.maxArea, values.floatValue);
									}}
									value={state[filterKeys.maxArea]}
									className="text-[10px] text-black font-bold outline-none border-none w-full"
								/>
							</div>
						</div>
					</div>
					{/* buttons */}
					<div className="flex space-x-[6px] items-center">
						<Button
							text={"Clear All"}
							onClick={clearAll}
							classNames={"!border !border-[#848484] !h-[46px] !bg-white !rounded-[25px] !flex-1"}
						/>
						<Button
							loading={isLoading}
							text={"Apply"}
							classNames={"!border !border-_25253C !h-[46px] !bg-_25253C !rounded-[25px] !flex-1"}
							textClassNames={"!text-white"}
							onClick={onApply}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PropertiesFilterPopUp;
