import React from "react";
import clsx from "classnames";
import SectionHeader from "./SectionHeader";
import { ReactComponent as Correct } from "modules/home/assets/icons/correct.svg";
import FadeIn from "./FadeIn";

const steps = [
	{
		header: "Step 1: Offer",
		list: [
			"Fill out the form on this page",
			"We contact you to get information",
			"We do not share your information",
			"We make a Cash Offer",
		],
	},
	{
		header: "Step 2: Contract",
		list: ["If you accept the offer, we send a contract", "You sign the contract"],
	},
	{
		header: "Step 3: Get Cash",
		list: ["You choose a closing date", "You get paid CASH (in as little as 7 days)!"],
	},
];

const HowItWorks = () => {
	return (
		<FadeIn>
			<div className="px-5 pb-[155.5px] xl:pb-[191px] xl:max-w-[1173px] xl:mx-auto">
				<div className="flex flex-col space-y-[38px] items-center justify-center xl:flex-row xl:items-start xl:justify-start xl:space-y-0 xl:space-x-[196px]">
					<SectionHeader text={"How It Works"} />
					<div className="relative pt-[43px] pb-[34.5px] mx-[39px] xl:pt-0 xl:pb-0 xl:mx-0">
						{/* extra lines on desktop */}
						<div className="h-[3px] w-full bg-_41F153 absolute top-0 left-0 rounded-[3px] hidden xl:block" />
						{/* end extra lines on desktop */}
						{/* extra lines on mobile */}
						<div className="h-[43px] w-[2.59px] bg-_41F153 absolute top-0 left-[6.465px] rounded-t-[3px] xl:hidden" />
						<div className="h-[34.5px] w-[2.59px] bg-_41F153 absolute bottom-0 left-[6.465px] rounded-b-[3px] xl:hidden" />
						{/* end extra lines on mobile */}
						<div className="xl:flex xl:space-x-[75px]">
							{steps.map((step, index) => (
								<div
									className={clsx("pl-[42px] relative xl:pt-[46px] xl:pl-0", {
										"pb-[75px] xl:pb-0": index !== steps.length - 1,
									})}
									key={index}
								>
									<div className="h-[15.52px] w-[15.52px] bg-_41F153 rounded-full absolute top-[5px] left-0 xl:h-[18px] xl:w-[18px] xl:left-[56px] xl:top-[-9px]" />
									<div className="h-full w-[2.59px] bg-_41F153 absolute top-0 left-[6.465px] xl:hidden" />
									<h3 className="font-bold text-[18px] leading-[28px] text-_black mb-[20.18px]">
										{step.header}
									</h3>
									<div className="flex flex-col space-y-5">
										{step.list.map((item, index) => (
											<div className="flex space-x-3" key={index}>
												<Correct className="flex-shrink-0" />
												<span className="text-base text-_black">{item}</span>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</FadeIn>
	);
};

export default HowItWorks;
