/* eslint-disable react-hooks/exhaustive-deps */
import { Button, NavigationWrapper, Pagination } from "modules/common/components";
import clsx from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as DiamondsLarge } from "modules/home/assets/images/diamonds-lg.svg";
import { BlogCard, BlogCardSkeleton, PopularBlogs } from "../components";
import CONSTANTS from "modules/common/utils/constants";
import API from "api";
import { useAuth, useDebouncedLoader } from "modules/common/hooks";

const {
	BLOG: { PRODUCTIVITY, TIPS_AND_TRICKS, MUST_READ },
} = CONSTANTS;
const LATEST = "LATEST";

const BlogDeleteModal = ({ blogToDelete, onClose, onBlogDeleteSuccess }) => {
	const [loading, setLoading] = useState(false);

	const onBlogDelete = async () => {
		setLoading(true);
		try {
			await API.admin.deleteBlog(blogToDelete._id);
			onClose();
			await onBlogDeleteSuccess();
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	if (!blogToDelete) {
		return null;
	}
	return (
		<div
			className="bg-black/[.7] fixed h-screen w-screen top-0 left-0 p-2 z-[100] flex items-center justify-center"
			onClick={onClose}
		>
			<div
				className="bg-white rounded-lg max-w-[600px] lg:w-[600px] flex flex-col space-y-4 items-center justify-center p-5"
				onClick={(e) => e.stopPropagation()}
			>
				<p>Are you sure you want to delete this blog?</p>
				<br />
				<h2 className="font-bold">"{blogToDelete?.title}"</h2>
				<div className="flex flex-col space-y-2 md:space-y-0 md:flex-row items-center justify-center md:space-x-3">
					<Button
						text={"Delete"}
						classNames={"bg-[red]"}
						textClassNames={"text-white"}
						onClick={() => onBlogDelete()}
						loading={loading}
					/>
					<Button text={"Cancel"} onClick={onClose} />
				</div>
			</div>
		</div>
	);
};

const BlogMainPage = () => {
	const [blogToDelete, setBlogToDelete] = useState(null);
	const [blogs, setBlogs] = useState({
		[LATEST]: {},
		[PRODUCTIVITY]: {},
		[TIPS_AND_TRICKS]: {},
		[MUST_READ]: {},
	});
	const [activeTab, setActiveTab] = useState(LATEST);
	const [loading, setLoading] = useState(false);
	const [refreshPopularPosts, setRefreshPopularPosts] = useState(false);

	const getCategoryBlogs = async (page, category) => {
		setLoading(true);
		try {
			const { data, metadata } = await API.getBlogsForCategory({ page, category });
			setBlogs({ ...blogs, [category]: { data, metadata } });
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	const dataAndMetaData = useMemo(() => {
		return {
			metadata: blogs[activeTab]?.metadata || {},
			data: blogs[activeTab]?.data || [],
		};
	}, [activeTab, blogs]);

	useEffect(() => {
		const page = blogs[activeTab]?.metaData?.page || 1;
		getCategoryBlogs(page, activeTab);
	}, [activeTab]);

	const isLoading = useDebouncedLoader(loading);
	const { authInfo } = useAuth();

	const onDeleteTrial = (blog) => {
		setBlogToDelete(blog);
	};

	return (
		<NavigationWrapper>
			<BlogDeleteModal
				blogToDelete={blogToDelete}
				onClose={() => setBlogToDelete(null)}
				onBlogDeleteSuccess={() => {
					const page = blogs[activeTab]?.metaData?.page || 1;
					getCategoryBlogs(page, activeTab);
					setRefreshPopularPosts(true);
					setTimeout(() => {
						setRefreshPopularPosts(false);
					}, 1000);
				}}
			/>
			<div className="bg-_blue h-[120px] lg:hidden" />
			{/* blog menu for large screens */}
			<div className="flex items-center 1300:justify-center pt-8 px-5 space-x-3 lg:pt-[202px] 1300:space-x-[58px] !no-scrollbar bg-white overflow-x-auto whitespace-nowrap">
				{[LATEST, PRODUCTIVITY, TIPS_AND_TRICKS, MUST_READ].map((tab) => (
					<button
						onClick={() => {
							if (tab !== activeTab) {
								setActiveTab(tab);
							}
						}}
						className={clsx("text-_25253C text-base font-bold leading-[18px] uppercase", {
							"!text-FEDF18": tab === activeTab,
						})}
						key={tab}
					>
						{tab}
					</button>
				))}
			</div>
			{/* moving carousel */}
			<div />
			{/* end blog menu for large screens */}
			<div className="px-5 bg-white pt-[137px] lg:pt-[188px] 1300:pt-[62px] relative xl:flex xl:space-x-[35px] 1300:max-w-[1320px] 1300:mx-auto">
				<DiamondsLarge className="hidden 1300:block absolute top-0 left-0 h-full w-full" />
				{/* blog portion */}
				<div className="flex-1">
					<div className="flex flex-col space-y-[30px] mb-[50px] 1300:grid 1300:grid-cols-2 1300:space-y-0 1300:gap-x-5 1300:gap-y-5">
						{isLoading && !dataAndMetaData.data.length ? (
							<>
								{Array(10)
									.fill(0)
									.map((item, index) => (
										<BlogCardSkeleton key={index} />
									))}
							</>
						) : (
							<>
								{dataAndMetaData.data.map((blog) => (
									<BlogCard
										id={blog._id}
										key={blog._id}
										title={blog.title}
										createdAt={blog.createdAt}
										coverPhotoUrl={blog.coverPhotoUrl}
										// onDelete={}
										showDeleteBtn={!!authInfo}
										onDeleteTrial={() => onDeleteTrial(blog)}
									/>
								))}
							</>
						)}
					</div>
					<Pagination
						metadata={dataAndMetaData.metadata}
						onPageChange={(page) => {
							getCategoryBlogs(page, activeTab);
						}}
					/>
				</div>
				{/* popular posts */}
				<PopularBlogs refresh={refreshPopularPosts} />
			</div>
		</NavigationWrapper>
	);
};

export default BlogMainPage;
