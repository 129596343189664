import React, { useState } from "react";
import clsx from "classnames";
import faqs from "modules/home/utils/faq.json";
import FadeIn from "./FadeIn";

const FAQInstance = ({ index, faq: { question, answer } }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleExpansion = () => {
		setIsExpanded(!isExpanded);
	};
	return (
		<div
			className={clsx(
				"max-h-[100px] transition-all duration-500 overflow-hidden",
				{ "pb-5 border-b border-b-_blue": index !== faqs.length - 1 },
				{ "pt-[17px]": index !== 0 },
				{ "!max-h-[400px]": isExpanded }
			)}
		>
			<div className="flex justify-between items-center cursor-pointer w-full" onClick={toggleExpansion}>
				<p className="font-bold text-[18px] leading-[28px] text-_black xl:text-[24px] xl:leading-[34px]">
					{question}
				</p>
				<div className="flex-shrink-0 ml-2">
					{isExpanded ? (
						<div className="w-[14px] h-[3px] bg-_25253C rounded-[3px]" />
					) : (
						<div className="relative w-[14px] h-[14px]">
							<div className="h-[14px] w-[3px] bg-_25253C absolute top-0 left-[5.5px] rounded-[3px]" />
							<div className="w-[14px] h-[3px] bg-_25253C absolute top-[5.5px] left-0 rounded-[3px]" />
						</div>
					)}
				</div>
			</div>
			<div
				className={clsx(
					"max-h-[0] transition-all opacity-0 duration-500",
					{ "!max-h-[900px] pt-[15px] opacity-100": isExpanded },
					{ "overflow-hidden": !isExpanded }
				)}
			>
				<p className="text-base leading-6 text-_black">{answer}</p>
			</div>
		</div>
	);
};

const FAQ = () => {
	return (
		<div className="bg-white pb-[84px] px-5 xl:px-[172px]">
			<FadeIn>
				<div className="max-w-[998px] xl:mx-auto">
					<h1
						id="faqs"
						className="text-_black font-bold text-[40px] leading-[44px] px-5 mb-[50px] xl:mb-[64px] xl:text-[56px] xl:leading-[61.6px]"
					>
						Frequently Asked Questions
					</h1>
					<div>
						{faqs.map((faq, index) => (
							<FAQInstance key={index} faq={faq} index={index} />
						))}
					</div>
				</div>
			</FadeIn>
		</div>
	);
};

export default FAQ;
